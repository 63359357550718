
import { Component, Vue } from 'vue-property-decorator';
import { getPostView, deletePost, likePost } from '@/api/post';
import router from '@/router';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getPost();
  }

  private post: any = {
    uid: '',
    title: '',
    content: '',
    writer: '',
    viewCount: 0,
    noticeStatus: false,
    hasAuthority: false,
    children: [],
  };

  private postLike: any = {
    idx: 0,
    postUid: '',
    boardUid: '',
    userUid: '',
    parenUid: '',
  }

  private loading = true;

  private active = false;

  private getPost() {
    this.loading = true;
    getPostView(this.$route.params.postUid).then((res) => {
      this.post = res.data;
      this.active = res.data.myLikeFlag;
      this.loading = false;
    }).catch(() => {
      this.$message.error('게시글을 불러오는데 실패했습니다.');
      this.$router.push({ name: 'Inquiry' });
    });
  }

  private handleUpdatePost() {
    this.$router.push({ name: 'InquiryUpdate', params: { postUid: this.$route.params.postUid } });
  }

  private handleDeletePost() {
    this.$confirm('게시글을 삭제하시겠습니까?', '게시글 삭제', {
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deletePost(this.$route.params.postUid).then(() => {
        this.$message.success('게시글을 삭제했습니다.');
        this.$router.push({ name: 'Inquiry' });
      });
    });
  }

  private goBack() {
    this.$router.go(-1);
  }

  private handleClick() {
    this.likePost();
  }

  // 도움이 되었어요 api
  private likePost() {
    this.postLike.postUid = this.$route.params.postUid;
    likePost(this.$route.params.postUid, this.postLike).then((res) => {
      if (res.status === 200) {
        if (this.active) {
          this.active = false;
        } else {
          this.active = true;
        }
      }
      this.getPost();
    }).catch((error) => {
      console.error(error);
    });
  }

  private isCategory(uid: string) {
    switch (uid) {
      case '6b5789ad-325e-4cd8-857c-c76dd4956a81':
        return 'status category';
      case '7761ed23-73fb-4049-ac40-1f36c5e7e1c2':
        return 'status cancel';
      case '990e754f-7e95-4877-b36d-e77aedb37efd':
        return 'status reserve';
      default:
        return 'status category';
    }
  }

  private baseUrl = process.env.VUE_APP_TARGET;
}
